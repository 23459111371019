import React from 'react'
import TV from '../components/TV/TV'

const Console = () => {
  return (
    <>
      <TV />      
    </>
  )
}

export default Console